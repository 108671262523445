import BlankLayout from 'layouts/blank';
import Image from 'next/image';
import Link from 'next/link';

const NotFoundPage = () => {
  return (
    <div className="flex items-center justify-center h-full w-full gap-10 px-5">
      <div className="flex flex-col items-center text-white">
        <Image src="/icons/404.svg" alt="" width={320} height={120} />
        <div className="font-bold text-2xl pt-10 flex flex-col md:flex-row items-center">
          <span>Oh No!&nbsp;</span>
          <span>Something is missing!</span>
        </div>
        <div className="text-base text-[#E5E5EA] text-center pt-4 flex flex-col md:flex-row">
          <span>Page does not exist or has been moved.!&nbsp;</span>
          <span>Please go back to the homepage.</span>
        </div>
        <div>
          <Link href="/">
            <button className="py-3 px-7.5 rounded-lg border-1 border-white mt-10">Back to Homepage</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

NotFoundPage.Layout = BlankLayout;
export default NotFoundPage;
