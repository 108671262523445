import Header from './Header';

const BlankLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <section className="flex flex-col min-h-screen bg-[#1A1A1C]">
      <Header />
      <main className="flex-grow flex-shrink basis-0">{children}</main>
    </section>
  );
};

export default BlankLayout;
